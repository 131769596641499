export default function (file) {
	let filename, extension;
	if (file && file.filename) {
		filename = file.filename.split(".");
		file.id = file.id ? file.id.replace(":", "") : "";
		extension = filename.slice(-1).pop();
		filename.pop();
		filename = filename.join(".");
		return {
			href: "/binaries/" + filename + "_" + file.id + "." + extension,
			extension: extension,
		};
	} else if (file.constructor === String) {
		filename = file.split(".");
		if (filename) {
			extension = filename.slice(-1);
		}
		return {
			href: filename.join("."),
			extension: extension,
		};
	}
}
