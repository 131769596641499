import FileViewModel from "./FileViewModel.js";

const internalWhitelist = ["dc.corp", "test", "staging", "www", "localhost"];

const notMineSider = (pathname) => pathname && pathname.indexOf("minesider") === -1;
const notMinBedrift = (pathname) => pathname && pathname.indexOf("mbw") === -1;
const isTelenorHost = (hostname) => hostname && hostname.indexOf("telenor.no") > -1;

const safeParseUrl = (url) => {
	try {
		return (url && new URL(url)) || { hostname: "", pathname: "", search: "" };
	} catch (err) {
		return { hostname: "", pathname: "", search: "" };
	}
};

export default function (link) {
	if (!link) {
		return null;
	}

	let to = link.internalUrl || link.internalLink || link.to || link.buttonUrl?.internalUrl;
	if (to) {
		to = to.replace("index.page", "");
	}
	let href =
		link.externalLink ||
		link.externalUrl ||
		link.url ||
		(link.file && FileViewModel(link.file).href) ||
		link.href ||
		link.buttonUrl?.externalUrl;
	const text = link.linkText || (link.file && link.file.filename) || link.text || link.buttonText;

	if (!to && !href && !text) {
		return null;
	}

	if (
		href &&
		href.indexOf("/") < 2 &&
		!link.file &&
		href.toLowerCase().indexOf("javascript:") < 0 &&
		href.toLowerCase().indexOf("tel:") < 0 &&
		href.indexOf("/login") !== 0 &&
		href.indexOf("minesider") < 0 &&
		href.indexOf("mbw") < 0
	) {
		to = href;
		href = null;
	}

	if (href?.indexOf("http") > -1) {
		let { hostname, pathname, search, hash } = safeParseUrl(href);
		if (isTelenorHost(hostname)) {
			if (
				internalWhitelist.some((prefix) => hostname.includes(prefix)) &&
				notMineSider(pathname) &&
				notMinBedrift(pathname)
			) {
				to = `${pathname}${search}${hash}` || "/";
				href = null;
			}
		}
	}

	return {
		href,
		to,
		text,
		title: link.alternateText || link.title,
		openInNewWindow: link.openInNewWindow,
		chevronRight: link.linkArrow,
		chevronAnimated: link.linkArrow,
	};
}
